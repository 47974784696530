import React from 'react';
import { Link } from 'gatsby';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import {
  faGlobe,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import {
  faGoogle,
  faFacebook,
  faApple,
  faReddit,
  faDiscord,
  faSteam
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../generic-page.scss';

const CSAboutPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>About the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/counterside/categories/category_awaken.png"
            alt="About the game"
          />
        </div>
        <div className="page-details">
          <h1>About the game</h1>
          <h2>Learn more about Counter Side.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      <div className="page-content">
        <SectionHeader title="Counter Side" />
        <p>
          Counter Side is a 2D tower defense gacha game developed by Studiobside
          Co.,Ltd. You take the role of being a CEO and assign Counters,
          Soldiers and Mechs to fight against unknown monsters in order to
          protect the world. The game was first released in South Korea in 2019,
          but since then it was released in other regions: TW, CN, SEA, JPN and
          most recently, Global.
        </p>
        <SectionHeader title="Official description" />
        <p>
          Urban fantasy collective RPG CounterSide - the game for anime fans!
        </p>
        <p>
          While the Administration Failure led to Corrupted Objects threatening
          the very existence of humanity, A chosen few, known as the Counters,
          fight against these Corrupted Objects to drive them back to the void.
          Now, the ceased clock ticks once more.
        </p>
        <h6>Legendary Animated Illustration Quality</h6>
        <p>
          Save the world by commanding attractive characters with mind-blowing
          animated illustrations.
        </p>
        <h6>Thrilling Line Battle System</h6>
        <p>
          From gorgeous girls to astonishing mechanical units to select!
          Strategize with realistic characters in the real-time PVP arena for
          victory!
        </p>
        <h6>Countless rich scenarios on a whole new level (500,000+ words)</h6>
        <p>
          Enjoy the vast emotional stories encompassing SF, Noir, and
          Post-Apocalypse genres. Feel the animation-like stories characters are
          going through in a world that repeats destruction.
        </p>
        <h6>Refined BGM and Charming Voice</h6>
        <p>
          Savor the world of CounterSide through high-quality BGM and immersive
          voices.
        </p>
        <h6>Vast Content and Convenient Play</h6>
        <p>
          Experience various contents conveniently with skip and auto play
          functions in Story, Arena, Raid, Dive, and more!
        </p>
        <SectionHeader title="Promotional images" />
        <StaticImage
          src="../../images/counterside/cs_promo_1.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/counterside/cs_promo_2.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/counterside/cs_promo_3.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/counterside/cs_promo_4.webp"
          alt="Promotional image"
        />
        <StaticImage
          src="../../images/counterside/cs_promo_5.webp"
          alt="Promotional image"
        />
        <SectionHeader title="Official links" />
        <Tabs
          defaultActiveKey="Global"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="Global" title="Global">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://counterside.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faFacebook} /> Facebook
                    </div>
                    <div className="list-value">
                      <a
                        href="https://www.facebook.com/CounterSideGlobal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.studiobside.CounterSide"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/us/app/counterside/id1309394370"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faSteam} /> Steam
                    </div>
                    <div className="list-value">
                      <a
                        href="https://store.steampowered.com/app/1976440/CounterSide/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">
                      Official Reddit -
                      <a
                        href="https://www.reddit.com/r/CounterSideGlobal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">
                      Official Discord -
                      <a
                        href="https://discord.com/invite/countersideglobal"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="KR" title="KR">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://forum.nexon.com/counterside/main"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faFacebook} /> Facebook
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.nexon.counterside&hl=ko&gl=US"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/kr/app/%EC%B9%B4%EC%9A%B4%ED%84%B0%EC%82%AC%EC%9D%B4%EB%93%9C/id1447944075"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">-</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="JPN" title="JPN">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGlobe} /> Website
                    </div>
                    <div className="list-value">
                      <a
                        href="https://counterside.nexon.co.jp/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faFacebook} /> Facebook
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faReddit} /> Reddit
                    </div>
                    <div className="list-value">-</div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faDiscord} /> Discord
                    </div>
                    <div className="list-value">-</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PC client" />
        <p>
          Counter Side has an official PC client, so you don't have to use
          emulators to play the game on PC. You can find links to download the
          PC client on the official website for each region.
        </p>
        <p>Please be aware that:</p>
        <ul>
          <li>
            the KR version's PC client can only be downloaded if you have an
            South Korea phone number,
          </li>
          <li>the Global version's PC client is available only on Steam.</li>
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default CSAboutPage;

export const Head: React.FC = () => (
  <Seo
    title="About the game | Counter Side | Prydwen Institute"
    description="Learn more about Counter Side."
    game="cs"
  />
);
